import React, {Component} from "react";
import {Link} from "react-router-dom";
import PropTypes from 'prop-types';
import { setTranslations, setDefaultLanguage, setLanguageCookie, setLanguage, translate } from 'react-switch-lang'; 
import en from '../languages/en.json';
import ru from '../languages/ru.json';

setTranslations({ en, ru });
// setDefaultLanguage('en');
setLanguageCookie();

class Sidebar extends Component {
  handleSetLanguage = (key) => () => {
    setLanguage(key);
  };

    render() {
      const { t } = this.props;
return (
    <div className="sidebar">
      <nav>
          <ul> 
          <li className={this.props.homepage}> 
              <Link data-text={t('sidebar.homepage')} to="/"><div>{t('sidebar.homepage')}</div></Link> 
            </li> 
            <li className={this.props.works}> 
              <Link data-text={t('sidebar.works')} to="/works"><div>{t('sidebar.works')}</div></Link> 
            </li>
            <li className={this.props.services}> 
              <Link data-text={t('sidebar.services')} to="/services"><div>{t('sidebar.services')}</div></Link> 
            </li> 
            <li className={this.props.contact}> 
              <Link data-text={t('sidebar.contact')} to="/contact"><div>{t('sidebar.contact')}</div></Link> 
            </li> 
          </ul>        
        </nav>

<a href="#fullscreenTrigger" className="fullscreenTrigger">
    <img className="open" alt="open" src="/img/fullscreen.svg" />
    <img className="close" alt="close" src="/img/fullscreen_close.svg" />
</a>

<div className="langWrapper">
  <a href="#/" onClick={this.handleSetLanguage('ru')}>
    <span data-hover="Рус">Рус</span>
  </a>
</div>

</div>
)
}
}

Sidebar.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(Sidebar);