import React from "react";
import Sidebar from './components/sidebar';
import Form from './components/form';
import PropTypes from 'prop-types';
import { setTranslations, setDefaultLanguage, setLanguageCookie, setLanguage, translate } from 'react-switch-lang'; 
import en from './languages/en.json';
import ru from './languages/ru.json';

setTranslations({ en, ru });
// setDefaultLanguage('en');
setLanguageCookie();

class Contact extends React.Component {
    handleSetLanguage = (key) => () => {
        setLanguage(key);
        };
  render() {
    const { t } = this.props;
      return (
          <>
    <div className="scrollWrapper">

    <header className="home">
    <a href="/" className="logoWrapper">
        <img src="/img/logo.svg" alt="DREAMDEV" />
    </a>
    <a href="#menuTrigger" className="menuTrigger">
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
    </a>
</header>

        
<div className="mainWrapper">

    <section className="contact">
        <h1>{t('contact.contact')}</h1>
        <div className="contactInfo">
            <div className="contactRow">
                <div className="label">
                    <span>{t('contact.work_email')}</span>
                </div>
                <div className="value">
                    <div>
                        <a href="mailto:mail@dreamdev.dk" className="blindHover">
                            <span data-hover="mail@dreamdev.dk">mail@dreamdev.dk</span>
                        </a>
                    </div>
                </div>
            </div>
            <div className="contactRow">
                <div className="label">
                    <span>{t('contact.sales_email')}</span>
                </div>
                <div className="value">
                    <div>
                        <a href="mailto:mail@dreamdev.dk" className="blindHover">
                            <span data-hover="sales@dreamdev.dk">sales@dreamdev.dk</span>
                        </a>
                    </div>
                </div>
            </div>

            <div className="contactRow social">
                <div className="label">
                    <span>{t('contact.we_in_social')}</span>
                </div>
                <div className="value">
                    <div>
                        <a href="https://www.facebook.com/" rel="noopener noreferrer" target="_blank" className="blindHover">
                            <span data-hover="Facebook">Facebook</span>
                        </a>
                    </div>
                    {/* <div>
                        <a href="https://www.behance.net/" rel="noopener noreferrer" target="_blank" className="blindHover">
                            <span data-hover="Behance">Behance</span>
                        </a>
                    </div>

                    <div>
                        <a href="https://www.instagram.com/" rel="noopener noreferrer" target="_blank" className="blindHover">
                            <span data-hover="Instagram">Instagram</span>
                        </a>
                    </div> */}
                </div>
            </div>
        </div>
        <div className="infoTextRight" id="on-form">{t('contact.hire_us_now')}</div>
        <div className="infoTextLeft">{t('contact.works')}</div>

        <div className="formWrapper">
            
<Form />
        </div>
    </section>

</div>
<footer className="">
    <div className="copyright">
        2020 © "DREAMDEV" {t('contact.studio')}             </div>
</footer>

    </div>

<Sidebar contact="active" />
          </>
      )
  }
}

Contact.propTypes = {
    t: PropTypes.func.isRequired,
  };

  export default translate(Contact);