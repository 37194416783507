import React, {Component} from "react";
import PropTypes from 'prop-types';
import { setTranslations, setDefaultLanguage, setLanguageCookie, setLanguage, translate } from 'react-switch-lang'; 
import en from '../languages/en.json';
import ru from '../languages/ru.json';

setTranslations({ en, ru });
// setDefaultLanguage('en');
setLanguageCookie();

class Header extends Component {
    handleSetLanguage = (key) => () => {
        setLanguage(key);
        };
    render() {
        const { t } = this.props;
return (
    <header className="home">
    <a href="/" className="logoWrapper">
        <img src="/img/logo.svg" alt="DREAMDEV" />
    </a>
                    <a className="hireBtn btn scrollTrigger" href="/#form" data-text= {t('homepage.hireus')}>
            <div>
            {t('homepage.hireus')}                    </div>
        </a>
    <a href="#menuTrigger" className="menuTrigger">
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
    </a>
</header>
        )
    }
}

Header.propTypes = {
    t: PropTypes.func.isRequired,
  };

  export default translate(Header);