/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import ReactDOM from "react-dom";
import Home from "./home";
import Contact from "./contact";
import Services from "./services";
import Works from "./works";
import './css/animations.css';
import './css/index.css';
import './css/responsive.css';
import './css/aos.css';
import AOS from 'aos';
import {
  BrowserRouter,
  Switch,
  Route
} from "react-router-dom";

class App extends React.Component {
  constructor(props, context) {
        super(props, context);
        AOS.init({
          once: true,
          disable: 'phone'
        });
      }
  render() {
      return (
        <div>
        <BrowserRouter>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/works" component={Works} />
              <Route path="/contact" component={Contact} />
              <Route path="/services" component={Services} />
            </Switch>
          </BrowserRouter>

      </div>
      )
  }
}

ReactDOM.render(<App />, document.getElementById('root'));

export default App;
