import React from "react";
import Header from './components/header';
import Footer from './components/footer';
import Sidebar from './components/sidebar';
import $ from 'jquery';
import "fullpage.js/vendors/scrolloverflow"; // Optional. When using scrollOverflow:true
import ReactFullpage from "@fullpage/react-fullpage";
import './css/services.css';
import PropTypes from 'prop-types';
import { setTranslations, setDefaultLanguage, setLanguageCookie, setLanguage, translate } from 'react-switch-lang'; 
import en from './languages/en.json';
import ru from './languages/ru.json';

setTranslations({ en, ru });
// setDefaultLanguage('en');
setLanguageCookie();


class Services extends React.Component {
handleSetLanguage = (key) => () => {
    setLanguage(key);
    };

    onLeave(index, nextIndex, direction) {
                            if ($('.mainWrapper section').eq(nextIndex.index - 0).hasClass('hireUs')) {
                                $('header').addClass('hidden');
                            } else {
                                $('header').removeClass('hidden');
                            }
    
                            if ($('.servicesTitles').length) {
                                $('.servicesTitles a').removeClass('active');
                                $('.servicesTitles a').eq(nextIndex.index - 0).addClass('active');
                            }
    
                            $('.pager .bullets').addClass('s' + nextIndex.index);
                            setTimeout(function () {
                                $('.pager .bullets').removeClass('s' + index.index);
                                $('.pager .bullets .bullet').removeClass('active');
                                $('.pager .bullets .bullet').eq(nextIndex.index - 0).addClass('active');
                            }, 500);
    
                            if (nextIndex.index === 2) {
                                $('.pager .labelWrapper .label').addClass('end');
                                $('.home').addClass('hidden');
                            } else {
                                $('.pager .labelWrapper .label').removeClass('end');
                                $('.home').removeClass('hidden');
                            }

                                $(document).on('click', '.mobileSwitcher a:not(.active)', function (e) {
        e.preventDefault();
        var $el = $(e.currentTarget);
        $('.mobileSwitcher a').removeClass('active');
        $el.addClass('active');
        $('section.service').removeClass('active');
        $('section.service').eq($el.index()).addClass('active');
        return false;
    });
                            
      }
      afterLoad(anchorLink, index) {
        // var $section = $('.mainWrapper section').eq(index - 1);
      }

      render() {
        const { t } = this.props;
        return (
            <>
            <div className="scrollWrapper">
            <Header />
            <div className="mobileSwitcher servicesTitles">
    <a href="#/" className="active">{t('services.web')}<span className="counter">01</span></a>
    <a href="#/" className="">{t('services.mobile')}<span className="counter">02</span></a>
    <a href="#/" className="">{t('services.rules')}<span className="counter">03</span></a>
</div>

<h1>Services</h1>
          <ReactFullpage
          licenseKey = {'80F89912-495842AD-8512EFCC-AB48B4E9'}
            scrollOverflow={true}
            anchors={['web', 'mobile', 'rules']}
            onLeave={this.onLeave.bind(this)}
            afterLoad={this.afterLoad.bind(this)}
            sectionSelector={'fp-section'}
            css3={true}
            scrollingSpeed={1000}
            easing={'easeInOutCubic'}
            easingcss3={'cubic-bezier(0.645, 0.045, 0.355, 1)'}
            verticalCentered={false}
            render={({ state, fullpageApi }) => {
              return (
                <>


<div className="mainWrapper fullpage fullpage-wrapper" >

    <section className="service web fp-section active fp-completely">
{/* <div className="title">
<span className="counter">01</span>
</div> */}
        <div className="description">
            <div>{t('services.our_prof_team')}</div>
            <div>{t('services.we_use')}</div>
            <div>{t('services.you_can')}</div>
        </div>
        <div className="list">
            <ul>
                <li>
                    <span>{t('services.online_store')}</span>
                </li>
                <li>
                    <span>{t('services.corporate_site')}</span>
                </li>
                <li>
                    <span>{t('services.brand_site')}</span>
                </li>
                <li>
                    <span>{t('services.promo_site')}</span>
                </li>
                <li>
                    <span>{t('services.landing_page')}</span>
                </li>
                <li>
                    <span>{t('services.web_application')}</span>
                </li>
            </ul>
        </div>
        <div className="rect">
            <div className="resolution">
                1920 : 1080
            </div>
        </div>
    </section>
    <section className="service mobile fp-section">
{/* <div className="title">
<span className="counter">02</span>
</div> */}
        <div className="description">
            <div>
            {t('services.our_team_create')}
                           </div>
            <div>
            {t('services.we_approach')}
                            </div>
        </div>
        <div className="list">
            <ul>
                <li>
                    <span>iOS {t('services.application')}</span>
                </li>
                <li>
                    <span>Android {t('services.application')}</span>
                </li>
                <li>
                    <span>{t('services.platform')}</span>
                </li>
            </ul>
        </div>
        <div className="rect">
            <div className="resolution">
                320 : 480
            </div>
        </div>
    </section>
    <section className="service rules fp-section">
{/* <div className="title">
<span className="counter">03</span>
</div> */}
        <div className="description">
            <div>{t('services.save_our_time')}</div>
            <div className="thin">{t('services.sincerely_team_dream')}</div>
        </div>
        <div className="list">
            <ul>
                <li>
                    <span>{t('services.free')}</span>
                </li>
                <li>
                    <span>{t('services.with_unrealistic_deadlines')}</span>
                </li>
                <li>
                    <span>{t('services.without_tech_spec')}</span>
                </li>
                <li>
                    <span>{t('services.without_prepayment')}</span>
                </li>
                <li>
                    <span>{t('services.violence_and_pornography')}</span>
                </li>
                <li>
                    <span>{t('services.banners')}</span>
                </li>
                <li>
                    <span>{t('services.other_code')}</span>
                </li>
            </ul>
        </div>
        <div className="rect">
        </div>
    </section>

</div>
</>
          );
        }}
      />
<div className="pager left">
    <div className="bullets s0">
        <span className="bullet active"></span>
        <span className="bullet"></span>
        <span className="bullet"></span>
    </div>
    <div className="labelWrapper">
        <div className="label">{t('homepage.scroll_down')}</div>
    </div>
</div>
        <Footer />
    </div>
    <Sidebar services="active" />
          </>
      )
  }
}

Services.propTypes = {
    t: PropTypes.func.isRequired,
  };

  export default translate(Services);