/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
// import ReactDOM from "react-dom";
import "fullpage.js/vendors/scrolloverflow"; // Optional. When using scrollOverflow:true
import ReactFullpage from "@fullpage/react-fullpage";
import $ from 'jquery';
import Sidebar from './components/sidebar';
import Footer from './components/footer';
import Header from './components/header';
import Form from './components/form';
import PropTypes from 'prop-types';
import { setTranslations, setDefaultLanguage, setLanguageCookie, setLanguage, translate } from 'react-switch-lang'; 
import en from './languages/en.json';
import ru from './languages/ru.json';

setTranslations({ en, ru });
// setDefaultLanguage('en');
setLanguageCookie();



class Home extends React.Component {  
    handleSetLanguage = (key) => () => {
        setLanguage(key);
      };

  onLeave(index, nextIndex, direction) {
    console.log(nextIndex.index);
    $('section.intro').on("mousemove", function (e) {
        if ($(window).width() > 1023) {
            var w = $('section.intro').width(),
                h = $('section.intro').height(),
                x = e.clientX,
                y = e.clientY,
                indX = Math.ceil(x / (w / 5)),
                indY = Math.ceil(y / (h / 4));
            if (!indX || !indY) {
                return false;
            }
            var ind = ((indY - 1) * 5) + indX;
            $('section.intro .mainImage').attr('class', 'mainImage state-' + ind);
        }
    });
                        if ($('.mainWrapper section').eq(nextIndex.index - 0).hasClass('hireUs')) {
                            $('header').addClass('hidden');
                        } else {
                            $('header').removeClass('hidden');
                        }

                        if ($('.servicesTitles').length) {
                            $('.servicesTitles a').removeClass('active');
                            $('.servicesTitles a').eq(nextIndex.index - 0).addClass('active');
                        }

                        $('.pager .bullets').addClass('s' + nextIndex.index);
                        setTimeout(function () {
                            $('.pager .bullets').removeClass('s' + index.index);
                            $('.pager .bullets .bullet').removeClass('active');
                            $('.pager .bullets .bullet').eq(nextIndex.index - 0).addClass('active');
                        }, 500);

                        if (nextIndex.index === 2) {
                            $('.pager .labelWrapper .label').addClass('end');
                            $('.home').addClass('hidden');
                        } else {
                            $('.pager .labelWrapper .label').removeClass('end');
                            $('.home').removeClass('hidden');
                        }
  }
  afterLoad(anchorLink, index) {
    // var $section = $('.mainWrapper section').eq(index - 1);
  }

  render() {
    const { t } = this.props;
    return (
        <>
        <div className="scrollWrapper">
        <Header />
      <ReactFullpage
      licenseKey = {'80F89912-495842AD-8512EFCC-AB48B4E9'}
        scrollOverflow={true}
        anchors={['home', 'info', 'form']}
        onLeave={this.onLeave.bind(this)}
        afterLoad={this.afterLoad.bind(this)}
        sectionSelector={'section'}
        css3={true}
        scrollingSpeed={1000}
        easing={'easeInOutCubic'}
        responsiveWidth= {900}
        easingcss3={'cubic-bezier(0.645, 0.045, 0.355, 1)'}
        verticalCentered={false}
        render={({ state, fullpageApi }) => {
          return (
            <>
<div className="mainWrapper fullpage fullpage-wrapper">
<section className="intro active fp-section fp-completely">
    <div className="topBlock revealLTR" data-aos="fade">
        <div className="mainImage state-13">
            <img src={t('homepage.studioimg')} alt="studio" />
        </div>
    </div>
    <div className="bottomBlock">
        <h1 className="bigLabel">
            <div><span data-aos="fade" className="revealRTL">{t('homepage.design')} +</span></div>
            <div><span data-aos="fade" className="revealLTR">{t('homepage.development')}</span></div>
        </h1>

        <div className="desc">
            <div className="top">
                <span className="revealLTR" data-aos="fade">
                {t('homepage.ddsent1line')}                        </span>
            </div>
            <div className="bottom">
                <span className="revealRTL" data-aos="fade">
                {t('homepage.ddsent2line')}                        </span>
            </div>
        </div>
        <div className="awards">
            <div><span className="revealLTR" data-aos="fade">13+</span></div>
            <div><span className="revealRTL" data-aos="fade">{t('homepage.awards')}</span></div>
        </div>
    </div>
</section>

<section className="info fp-section">
    <div className="data">
        <div className="topBlock">
            <h2 className="title">
            {t('homepage.data')}                    </h2>
            <div className="desc">
                {t('homepage.infoaboutus')}                    </div>
        </div>
        <div className="bottomBlock">
            <div className="infoRow">
                <div className="label">
                    {t('homepage.country')} 
                </div>
                <div className="value">
                    {t('homepage.denmark')}                         </div>
            </div>
            <div className="infoRow">
                <div className="label">
                    {t('homepage.year')} :
                </div>
                <div className="value">
                    2020
                </div>
            </div>
            <div className="infoRow">
                <div className="label">
                    {t('homepage.type')} 
                </div>
                <div className="value">
                    {t('homepage.webstudio')}                         </div>
            </div>
            <div className="infoRow">
                <div className="label">
                    {t('homepage.emphasis')} 
                </div>
                <div className="value">
                    {t('homepage.trend')}                         </div>
            </div>
            <div className="infoRow">
                <div className="label">
                    {t('homepage.favorite_colors')} 
                </div>
                <div className="value">
                    #fff, #000
                </div>
            </div>
        </div>
    </div>
    <div className="awards">
        <div className="topBlock">
            <h2 className="title non">{t('homepage.awards')} </h2>
            <div className="desc">
                  {t('homepage.achievements')}                  </div>
        </div>
        <div className="bottomBlock">
            <div className="infoRow">
                <div className="label">
                    Behance:
                </div>
                <div className="value">
                    6X FEATURED in interaction
                </div>
            </div>
            <div className="infoRow">
                <div className="label">
                    Awwwards:
                </div>
                <div className="value">
                    6X Honorable Mention
                </div>
            </div>
            <div className="infoRow">
                <div className="label">
                    CSSDesignAwards:
                </div>
                <div className="value">
                    1X Kudos
                </div>
            </div>
        </div>
    </div>
    <div className="marble"></div>
</section>

<section className="hireUs fp-section">
    <div className="textBlock">
        <div>
            {t('homepage.send_us_a')}                 </div>
        <div>
            {t('homepage.request_to')}                 </div>
        <div>
            {t('homepage.create')}                 </div>
        <div>
            {t('homepage.unique')}                 </div>
        <div>
            {t('homepage.project')}                 </div>
        <div>
            {t('homepage.for_you')}                 </div>
    </div>
    <div className="formBlock">
        <div className="text">
            <div>
            {t('homepage.hire_us_sent')} 
                                    </div>
        </div>
        <div className="formWrapper">
<Form />
               </div>
    </div>
</section>
</div>
</>
          );
        }}
      />
      <div className="pager bottom">
<div className="labelWrapper">
    <div className="label">{t('homepage.scroll_down')} </div>
</div>
<div className="bullets s0">
    <span className="bullet active"></span>
    <span className="bullet"></span>
    <span className="bullet"></span>
</div>
</div>

<img style={{position: 'absolute', left: '-99999px'}} alt="img-string" src="/img/mainBg/1.jpg" />
<img style={{position: 'absolute', left: '-99999px'}} alt="img-string" src="/img/mainBg/2.jpg" />
<img style={{position: 'absolute', left: '-99999px'}} alt="img-string" src="/img/mainBg/3.jpg" />
<img style={{position: 'absolute', left: '-99999px'}} alt="img-string" src="/img/mainBg/4.jpg" />
<img style={{position: 'absolute', left: '-99999px'}} alt="img-string" src="/img/mainBg/5.jpg" />
<img style={{position: 'absolute', left: '-99999px'}} alt="img-string" src="/img/mainBg/6.jpg" />
<img style={{position: 'absolute', left: '-99999px'}} alt="img-string" src="/img/mainBg/7.jpg" />
<img style={{position: 'absolute', left: '-99999px'}} alt="img-string" src="/img/mainBg/8.jpg" />
<img style={{position: 'absolute', left: '-99999px'}} alt="img-string" src="/img/mainBg/9.jpg" />
<img style={{position: 'absolute', left: '-99999px'}} alt="img-string" src="/img/mainBg/10.jpg" />
<img style={{position: 'absolute', left: '-99999px'}} alt="img-string" src="/img/mainBg/11.jpg" />
<img style={{position: 'absolute', left: '-99999px'}} alt="img-string" src="/img/mainBg/12.jpg" />
<img style={{position: 'absolute', left: '-99999px'}} alt="img-string" src="/img/mainBg/13.jpg" />
<img style={{position: 'absolute', left: '-99999px'}} alt="img-string" src="/img/mainBg/14.jpg" />
<img style={{position: 'absolute', left: '-99999px'}} alt="img-string" src="/img/mainBg/15.jpg" />
<img style={{position: 'absolute', left: '-99999px'}} alt="img-string" src="/img/mainBg/16.jpg" />
<img style={{position: 'absolute', left: '-99999px'}} alt="img-string" src="/img/mainBg/17.jpg" />
<img style={{position: 'absolute', left: '-99999px'}} alt="img-string" src="/img/mainBg/18.jpg" />
<img style={{position: 'absolute', left: '-99999px'}} alt="img-string" src="/img/mainBg/19.jpg" />
<img style={{position: 'absolute', left: '-99999px'}} alt="img-string" src="/img/mainBg/20.jpg" />

<Footer />
      </div>
<Sidebar homepage="active" />

</>
    );
  }
}

// ReactDOM.render(<Home />, document.getElementById('root'));
Home.propTypes = {
    t: PropTypes.func.isRequired,
  };

  export default translate(Home);