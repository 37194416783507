import React from 'react';
import emailjs from 'emailjs-com';

export default function ContactUs() {
    var formWrap = document.getElementById("w0");
    var form = document.getElementById("forma");
    var thankyou = document.getElementById("thankyou");
  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('dreamdev_smtp', 'template_3nU1g3ll', e.target, 'user_S3V2hUi3gY7dqtRL9J8Na')
      .then((result) => {
          console.log(result.text);
          document.getElementById('feedback-name').value = ''
          document.getElementById('reply_to').value = ''
          document.getElementById('message_html').value = ''
          formWrap.removeChild(form);
          thankyou.style.display = "block";
      }, (error) => {
          console.log(error.text);
      });
  }

  return (
      <div id="w0">
          <h1 id="thankyou" style={{position: 'absolute', left: '6vw'}}>MESSAGE SENT</h1>
    <form id="forma" onSubmit={sendEmail}>
        <input type="hidden" name="contact_number" />
<div>

<div className="form-group field-feedback-name required">

<input type="text" id="feedback-name" className="form-control" name="from_name" placeholder="Name" />

<p className="help-block help-block-error"></p>
</div>        <div className="form-group field-feedback-email required">

<input type="text" id="reply_to" className="form-control" name="reply_to" placeholder="Email" />

<p className="help-block help-block-error"></p>
</div>                <div className="form-group field-feedback-message required">

<textarea id="message_html" className="form-control" name="message_html" placeholder="Message"></textarea>
<p className="help-block help-block-error"></p>
</div>
<div className="submitWrapper">
    <button type="submit" className="btn ruClass" data-text="Send"><div>Send</div></button>        </div>

</div>

</form>
</div>
  );
}