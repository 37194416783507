import React from "react";
import Sidebar from './components/sidebar';
import PropTypes from 'prop-types';
import { setTranslations, setDefaultLanguage, setLanguageCookie, setLanguage, translate } from 'react-switch-lang'; 
import en from './languages/en.json';
import ru from './languages/ru.json';

setTranslations({ en, ru });
// setDefaultLanguage('en');
setLanguageCookie();

class Works extends React.Component {
    handleSetLanguage = (key) => () => {
        setLanguage(key);
        };
  render() {
    const { t } = this.props;
      return (
        <>
<div className="scrollWrapper" data-scrollbar="true" tabIndex="1"><div className="scroll-content">
<header className="static">
    <a href="/" className="logoWrapper">
        <img src="/img/logo.svg" alt="DREAMDEV" />
    </a>
                    <a className="hireBtn btn scrollTrigger" href="/#form" data-text={t('homepage.hireus')} >
            <div>
            {t('homepage.hireus')}                    </div>
        </a>
    <a href="#menuTrigger" className="menuTrigger">
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
    </a>
</header>
    <div className="mainWrapper static">
    
        <section className="works">
            {/* <h1>
                Works            <span className="counter">16</span>
            </h1> */}
            <h1>
                {t('works.works')}
            </h1>
    
    
            <div className="worksList">
    
                                            <div className="work n3">
                        <div className="imgWrapper revealLTR" data-aos="fade">
                            <div className="image" style={{backgroundImage: `url('/img/projects/18.jpg')`}}></div>
                        </div>
                        <div className="infoWrapper">
                            <div className="title">
                                <span>TWG Academy</span>
                            </div>
                            <div className="image" style={{backgroundImage: `url('/img/projects/18.jpg')`}}></div>
                            <div className="counter">
                                <span>{t('works.favorite')} #1</span>
                            </div>
                            <div className="desc">
                            The Welcome Group Academy                        </div>
                            <div className="action">
                            {t('works.design')} &amp; {t('works.dev')}                        </div>
                            <div className="btns">
                                <a href="https://twg-academy.com" className="btn" rel="noopener noreferrer" target="_blank" data-text={t('works.view')}>
                                    <div>{t('works.view')}</div>
                                </a>
                                                                <a href="https://twg-academy.com" rel="noopener noreferrer" target="_blank" className="outerLink">
                                        <img alt="link" src="/img/link.svg" />
                                    </a>
                                                        </div>
                        </div>
                    </div>
                                    <div className="work n4">
                        <div className="imgWrapper revealLTR" data-aos="fade">
                            <div className="image" style={{backgroundImage: `url('/img/projects/17.jpg')`}}></div>
                        </div>
                        <div className="infoWrapper">
                            <div className="title">
                                <span>WGC</span>
                            </div>
                            <div className="image" style={{backgroundImage: `url('/img/projects/17.jpg')`}}></div>
                            <div className="counter">
                                <span>{t('works.favorite')} #2</span>
                            </div>
                            <div className="desc">
                                The Welcome Group                        </div>
                            <div className="action">
                            {t('works.design')} &amp; {t('works.dev')}                        </div>
                            <div className="btns">
                                <a href="https://welcomegroupconsulting.com/" rel="noopener noreferrer" className="btn" target="_blank" data-text={t('works.view')}>
                                    <div>{t('works.view')}</div>
                                </a>
                                                                <a href="https://welcomegroupconsulting.com/" rel="noopener noreferrer" target="_blank" className="outerLink">
                                        <img alt="link" src="/img/link.svg" />
                                    </a>
                                                        </div>
                        </div>
                    </div>
                                    <div className="work n5">
                        <div className="imgWrapper revealLTR" data-aos="fade">
                            <div className="image" style={{backgroundImage: `url('/img/projects/15.jpg')`}}></div>
                        </div>
                        <div className="infoWrapper">
                            <div className="title">
                                <span>Velvet Spa</span>
                            </div>
                            <div className="image" style={{backgroundImage: `url('/img/projects/15.jpg')`}}></div>
                            <div className="counter">
                                <span>{t('works.favorite')} #3</span>
                            </div>
                            <div className="desc">
                                {t('works.velvet_spa_website')}                        </div>
                            <div className="action">
                            {t('works.design')} &amp; {t('works.dev')}                        </div>
                            <div className="btns">
                                <a href="https://velvetspa.dk/" rel="noopener noreferrer" className="btn" target="_blank" data-text={t('works.view')}>
                                    <div>{t('works.view')}</div>
                                </a>
                                                        </div>
                        </div>
                    </div>
                                    <div className="work n6">
                        <div className="imgWrapper revealLTR" data-aos="fade">
                            <div className="image" style={{backgroundImage: `url('/img/projects/14.jpg')`}}></div>
                        </div>
                        <div className="infoWrapper">
                            <div className="title">
                                <span>{t('works.graviden_app')}</span>
                            </div>
                            <div className="image" style={{backgroundImage: `url('/img/projects/14.jpg')`}}></div>
                            <div className="counter">
                                <span>{t('works.favorite')} #4</span>
                            </div>
                            <div className="desc">{t('works.graviden_app_desc')}</div>
                            <div className="action">UI/UX &amp; {t('works.dev')}</div>
                            {/* <div className="btns">
                                <a href="https://www.behance.net/gallery/65252599/Letundra-App-UIUX" rel="noopener noreferrer" className="btn" target="_blank" data-text={t('works.view')}>
                                    <div>View</div>
                                </a>
                                                        </div> */}
                        </div>
                    </div>
                                    <div className="work n1">
                        <div className="imgWrapper revealLTR" data-aos="fade">
                            <div className="image" style={{backgroundImage: `url('/img/projects/13.png')`}}></div>
                        </div>
                        <div className="infoWrapper">
                            <div className="title">
                                <span>Foodsharing Copenhagen</span>
                            </div>
                            <div className="image" style={{backgroundImage: `url('/img/projects/13.png')`}}></div>
                            <div className="counter">
                                <span>{t('works.favorite')} #5</span>
                            </div>
                            <div className="desc">
                            {t('works.fs_desc')}
                                                    </div>
                            <div className="action">
                            {t('works.design')} &amp; {t('works.dev')}                        </div>
                            <div className="btns">
                                <a href="http://foodsharingcph.org/" rel="noopener noreferrer" className="btn" target="_blank" data-text={t('works.view')}>
                                    <div>{t('works.view')}</div>
                                </a>
                                                                <a href="http://foodsharingcph.org/" rel="noopener noreferrer" target="_blank" className="outerLink">
                                        <img alt="link" src="/img/link.svg" />
                                    </a>
                                                        </div>
                        </div>
                    </div>
                                    <div className="work n2">
                        <div className="imgWrapper revealLTR" data-aos="fade">
                            <div className="image" style={{backgroundImage: `url('/img/projects/12.jpg')`}}></div>
                        </div>
                        <div className="infoWrapper">
                            <div className="title">
                                <span>EmployMe</span>
                            </div>
                            <div className="image" style={{backgroundImage: `url('/img/projects/12.jpg')`}}></div>
                            <div className="counter">
                                <span>{t('works.favorite')} #6</span>
                            </div>
                            <div className="desc">{t('works.employme_desc')}</div>
                            <div className="action">
                            {t('works.dev')}                        </div>
                            <div className="btns">
                                <a href="https://twg-employme.com/" rel="noopener noreferrer" className="btn" target="_blank" data-text={t('works.view')}>
                                    <div>{t('works.view')}</div>
                                </a>
                                                                <a href="https://twg-employme.com/" rel="noopener noreferrer" target="_blank" className="outerLink">
                                        <img alt="link" src="/img/link.svg" />
                                    </a>
                                                        </div>
                        </div>
                    </div>
                                    <div className="work n3">
                        <div className="imgWrapper revealLTR" data-aos="fade">
                            <div className="image" style={{backgroundImage: `url('/img/projects/11.png')`}}></div>
                        </div>
                        <div className="infoWrapper">
                            <div className="title">
                                <span>English Job Denmark</span>
                            </div>
                            <div className="image" style={{backgroundImage: `url('/img/projects/11.png')`}}></div>
                            <div className="counter">
                                <span>{t('works.favorite')} #7</span>
                            </div>
                            <div className="desc">{t('works.ejd_desc')}</div>
                            <div className="action">{t('works.dev')}</div>
                            <div className="btns">
                                <a href="https://englishjobdenmark.dk/" rel="noopener noreferrer" className="btn" target="_blank" data-text={t('works.view')}>
                                    <div>{t('works.view')}</div>
                                </a>
                                                                <a href="https://englishjobdenmark.dk/" rel="noopener noreferrer" target="_blank" className="outerLink">
                                        <img alt="link" src="/img/link.svg" />
                                    </a>
                                                        </div>
                        </div>
                    </div>
                                    {/* <div className="work n4">
                        <div className="imgWrapper revealLTR" data-aos="fade">
                            <div className="image" style={{backgroundImage: `url('/img/projects/10.jpg')`}}></div>
                        </div>
                        <div className="infoWrapper">
                            <div className="title">
                                <span>Mayak Agency</span>
                            </div>
                            <div className="image" style={{backgroundImage: `url('/img/projects/10.jpg')`}}></div>
                            <div className="counter">
                                <span>Favorite #09</span>
                            </div>
                            <div className="desc">
                                Moscow acting аgency                        </div>
                            <div className="action">
                                Design only                        </div>
                            <div className="btns">
                                <a href="https://www.behance.net/gallery/63720819/agentstvo-majak" rel="noopener noreferrer" className="btn" target="_blank" data-text={t('works.view')}>
                                    <div>View</div>
                                </a>
                                                        </div>
                        </div>
                    </div>
                                    <div className="work n5">
                        <div className="imgWrapper revealLTR" data-aos="fade">
                            <div className="image" style={{backgroundImage: `url('/img/projects/9.jpg')`}}></div>
                        </div>
                        <div className="infoWrapper">
                            <div className="title">
                                <span>New ZIPL in live</span>
                            </div>
                            <div className="image" style={{backgroundImage: `url('/img/projects/9.jpg')`}}></div>
                            <div className="counter">
                                <span>Favorite #08</span>
                            </div>
                            <div className="desc">
                                ZIPL v3.0 launched                        </div>
                            <div className="action">
                                Design &amp; dev                        </div>
                            <div className="btns">
                                <a href="https://www.behance.net/gallery/60485665/ZIPL-v-30" rel="noopener noreferrer" className="btn" target="_blank" data-text={t('works.view')}>
                                    <div>View</div>
                                </a>
                                                                <a href="http://zipl.pro" rel="noopener noreferrer" target="_blank" className="outerLink">
                                        <img alt="link" src="/img/link.svg" />
                                    </a>
                                                        </div>
                        </div>
                    </div>
                                    <div className="work n6">
                        <div className="imgWrapper revealLTR" data-aos="fade">
                            <div className="image" style={{backgroundImage: `url('/img/projects/8.jpg')`}}></div>
                        </div>
                        <div className="infoWrapper">
                            <div className="title">
                                <span>SUBURBIA</span>
                            </div>
                            <div className="image" style={{backgroundImage: `url('/img/projects/8.jpg')`}}></div>
                            <div className="counter">
                                <span>Favorite #07</span>
                            </div>
                            <div className="desc">
                                Arch studio website                        </div>
                            <div className="action">
                                Design &amp; dev                        </div>
                            <div className="btns">
                                <a href="https://www.behance.net/gallery/60663037/SUBURBIA" rel="noopener noreferrer" className="btn" target="_blank" data-text={t('works.view')}>
                                    <div>View</div>
                                </a>
                                                                <a href="http://suburbiastudio.com/" rel="noopener noreferrer" target="_blank" className="outerLink">
                                        <img alt="link" src="/img/link.svg" />
                                    </a>
                                                        </div>
                        </div>
                    </div>
                                    <div className="work n1">
                        <div className="imgWrapper revealLTR" data-aos="fade">
                            <div className="image" style={{backgroundImage: `url('/img/projects/7.jpg')`}}></div>
                        </div>
                        <div className="infoWrapper">
                            <div className="title">
                                <span>Robo Wunderkind</span>
                            </div>
                            <div className="image" style={{backgroundImage: `url('/img/projects/7.jpg')`}}></div>
                            <div className="counter">
                                <span>Favorite #06</span>
                            </div>
                            <div className="desc">
                                Promo website of robot constructors                        </div>
                            <div className="action">
                                Design &amp; dev                        </div>
                            <div className="btns">
                                <a href="https://www.behance.net/gallery/61701761/Robo-Wunderkind" rel="noopener noreferrer" className="btn" target="_blank" data-text={t('works.view')}>
                                    <div>View</div>
                                </a>
                                                                <a href="http://robowunderkind.com/" rel="noopener noreferrer" target="_blank" className="outerLink">
                                        <img alt="link" src="/img/link.svg" />
                                    </a>
                                                        </div>
                        </div>
                    </div>
                                    <div className="work n2">
                        <div className="imgWrapper revealLTR" data-aos="fade" >
                            <div className="image" style={{backgroundImage: `url('/img/projects/5.jpg')`}}></div>
                        </div>
                        <div className="infoWrapper">
                            <div className="title">
                                <span>MMDA</span>
                            </div>
                            <div className="image" style={{backgroundImage: `url('/img/projects/5.jpg')`}}></div>
                            <div className="counter">
                                <span>Favorite #05</span>
                            </div>
                            <div className="desc">
                                Model Agency website                        </div>
                            <div className="action">
                                Design only                        </div>
                            <div className="btns">
                                <a href="https://www.behance.net/gallery/54136291/MMDA" rel="noopener noreferrer" className="btn" target="_blank" data-text={t('works.view')}>
                                    <div>View</div>
                                </a>
                                                        </div>
                        </div>
                    </div>
                                    <div className="work n3">
                        <div className="imgWrapper revealLTR" data-aos="fade">
                            <div className="image" style={{backgroundImage: `url('/img/projects/4.jpg')`}}></div>
                        </div>
                        <div className="infoWrapper">
                            <div className="title">
                                <span>Igor Tsarenko</span>
                            </div>
                            <div className="image" style={{backgroundImage: `url('/img/projects/4.jpg')`}}></div>
                            <div className="counter">
                                <span>Favorite #04</span>
                            </div>
                            <div className="desc">
                                Personal website for IGOR TSARENKO                        </div>
                            <div className="action">
                                Design &amp; dev                        </div>
                            <div className="btns">
                                <a href="https://www.behance.net/gallery/45136421/IGOR-TSARENKO" rel="noopener noreferrer" className="btn" target="_blank" data-text={t('works.view')}>
                                    <div>View</div>
                                </a>
                                                                <a href="http://igortsarenko.me/" rel="noopener noreferrer" target="_blank" className="outerLink">
                                        <img alt="link" src="/img/link.svg" />
                                    </a>
                                                        </div>
                        </div>
                    </div>
                                    <div className="work n4">
                        <div className="imgWrapper revealLTR" data-aos="fade">
                            <div className="image" style={{backgroundImage: `url('/img/projects/3.jpg')`}}></div>
                        </div>
                        <div className="infoWrapper">
                            <div className="title">
                                <span>Arcadia SKY</span>
                            </div>
                            <div className="image" style={{backgroundImage: `url('/img/projects/3.jpg')`}}></div>
                            <div className="counter">
                                <span>Favorite #03</span>
                            </div>
                            <div className="desc">
                                Modern appartaments near the Black Sea                        </div>
                            <div className="action">
                                Design &amp; dev                        </div>
                            <div className="btns">
                                <a href="https://www.behance.net/gallery/51710313/Arkadia-Sky-(-Concept-)" rel="noopener noreferrer" className="btn" target="_blank" data-text={t('works.view')}>
                                    <div>View</div>
                                </a>
                                                        </div>
                        </div>
                    </div>
                                    <div className="work n5">
                        <div className="imgWrapper revealLTR" data-aos="fade">
                            <div className="image" style={{backgroundImage: `url('/img/projects/2.jpg')`}}></div>
                        </div>
                        <div className="infoWrapper">
                            <div className="title">
                                <span>Very Berry</span>
                            </div>
                            <div className="image" style={{backgroundImage: `url('/img/projects/2.jpg')`}}></div>
                            <div className="counter">
                                <span>Favorite #02</span>
                            </div>
                            <div className="desc">
                                Event agency                        </div>
                            <div className="action">
                                Design &amp; dev                        </div>
                            <div className="btns">
                                <a href="https://www.behance.net/gallery/46527317/VERY-BERRY" rel="noopener noreferrer" className="btn" target="_blank" data-text={t('works.view')}>
                                    <div>View</div>
                                </a>
                                                                <a href="https://veryberry.com.ua" rel="noopener noreferrer" target="_blank" className="outerLink">
                                        <img alt="link" src="/img/link.svg" />
                                    </a>
                                                        </div>
                        </div>
                    </div> 
                                    <div className="work n6">
                        <div className="imgWrapper revealLTR" data-aos="fade">
                            <div className="image" style={{backgroundImage: `url('/img/projects/1.png')`}}></div>
                        </div>
                        <div className="infoWrapper">
                            <div className="title">
                                <span>Reva Studio</span>
                            </div>
                            <div className="image" style={{backgroundImage: `url('/img/projects/1.png')`}}></div>
                            <div className="counter">
                                <span>Favorite #01</span>
                            </div>
                            <div className="desc">
                                Website for Reva Studio                         </div>
                            <div className="action">
                                Design &amp; dev                        </div>
                            <div className="btns">
                                <a href="https://www.behance.net/gallery/40141661/REVA-STUDIO" rel="noopener noreferrer" className="btn" target="_blank" data-text={t('works.view')}>
                                    <div>View</div>
                                </a>
                                                                <a href="http://revastudio.org/" rel="noopener noreferrer" target="_blank" className="outerLink">
                                        <img alt="link" src="/img/link.svg" />
                                    </a>
                                                        </div>
                        </div>
                    </div> */}
                            </div>
    
            <div className="worksText">
                
                <div>{t('works.see_more')}</div>
                <div>{t('works.on_the')}<a href="https://dribbble.com/" rel="noopener noreferrer" target="_blank" className="dribbble">Dribble </a>
<a href="https://www.behance.net/" rel="noopener noreferrer" target="_blank" className="behance">Behance</a></div>
            </div>
    
                        <a href="/works/ods">
                    <div style={{backgroundImage: `url('/img/projects/18.jpg')`}}></div>
                                </a>
                        <a href="/works/curo">
                    <div style={{backgroundImage: `url('/img/projects/17.jpg')`}}></div>
                                </a>
                        <a href="/works/infralytiks">
                    <div style={{backgroundImage: `url('/img/projects/15.jpg')`}}></div>
                                </a>
                        <a href="/works/letundra-app">
                    <div style={{backgroundImage: `url('/img/projects/14.jpg')`}}></div>
                                </a>
                        <a href="/works/icv-templates">
                    <div style={{backgroundImage: `url('/img/projects/13.png')`}}></div>
                                </a>
                        <a href="/works/franklead">
                    <div style={{backgroundImage: `url('/img/projects/12.jpg')`}}></div>
                                </a>
                        <a href="/works/letusbuy-ibiza">
                    <div style={{backgroundImage: `url('/img/projects/11.png')`}}></div>
                                </a>
                        <a href="/works/mayak-agency">
                    <div style={{backgroundImage: `url('/img/projects/10.jpg')`}}></div>
                                </a>
                        <a href="/works/zipl-v-3-0">
                    <div style={{backgroundImage: `url('/img/projects/9.jpg')`}}></div>
                                </a>
                        <a href="/works/suburbia">
                    <div style={{backgroundImage: `url('/img/projects/8.jpg')`}}></div>
                                </a>
                        <a href="/works/robo-wunderkind">
                    <div style={{backgroundImage: `url('/img/projects/7.jpg')`}}></div>
                                </a>
                        <a href="/works/mmda">
                    <div style={{backgroundImage: `url('/img/projects/5.jpg')`}}></div>
                                </a>
                        <a href="/works/igor-tsarenko">
                    <div style={{backgroundImage: `url('/img/projects/4.jpg')`}}></div>
                                </a>
                        <a href="/works/arcadia-sky">
                    <div style={{backgroundImage: `url('/img/projects/3.jpg')`}}></div>
                                </a>
                        <a href="/works/very-berry">
                    <div style={{backgroundImage: `url('/img/projects/2.jpg')`}}></div>
                                </a>
                        <a href="/works/reva-studio">
                    <div style={{backgroundImage: `url('/img/projects/1.png')`}}></div>
                                </a>
                </section>
    </div>
    <footer className="static">
    <div className="snWrapper  ">
        <div className="label">
        {t('works.social')}
        </div>
        <a href="https://www.behance.net/" rel="noopener noreferrer" target="_blank">
            <span data-hover="/ Behance">/ Behance</span>
        </a>
        <a href="https://www.facebook.com/" rel="noopener noreferrer" target="_blank">
            <span data-hover="/ Facebook">/ Facebook</span>
        </a>
        <a href="https://www.instagram.com/" rel="noopener noreferrer" target="_blank">
            <span data-hover="/ Instagram">/ Instagram</span>
        </a>
    </div>
    <div className="copyright">
        2020 © "DREAMDEV" {t('contact.studio')}            </div>
</footer>
        </div><div className="scrollbar-track scrollbar-track-x"><div className="scrollbar-thumb scrollbar-thumb-x"></div></div><div className="scrollbar-track scrollbar-track-y"><div className="scrollbar-thumb scrollbar-thumb-y"></div></div></div>
<Sidebar works="active" />
    </>
      )
  }
}

Works.propTypes = {
    t: PropTypes.func.isRequired,
  };

  export default translate(Works);