import React, {Component} from "react";
import PropTypes from 'prop-types';
import { setTranslations, setDefaultLanguage, setLanguageCookie, setLanguage, translate } from 'react-switch-lang'; 
import en from '../languages/en.json';
import ru from '../languages/ru.json';

setTranslations({ en, ru });
// setDefaultLanguage('en');
setLanguageCookie();

class Footer extends Component {
    handleSetLanguage = (key) => () => {
        setLanguage(key);
        };
    render() {
        const { t } = this.props;
return (
    <>
<footer className="">
    <div className="snWrapper  ">
        <div className="label">
        {t('works.social')} 
        </div>
        {/* <a href="https://www.behance.net/" rel="noopener noreferrer" target="_blank">
            <span data-hover="/ Behance">/ Behance</span>
        </a> */}
        <a href="https://www.facebook.com/" rel="noopener noreferrer" target="_blank">
            <span data-hover="/ Facebook">/ Facebook</span>
        </a>
        {/* <a href="https://www.instagram.com/" rel="noopener noreferrer" target="_blank">
            <span data-hover="/ Instagram">/ Instagram</span>
        </a> */}
    </div>
    <div className="copyright">
        2020 © "DREAMDEV" {t('contact.studio')}             </div>
</footer>

    </>
        )
    }
}

Footer.propTypes = {
    t: PropTypes.func.isRequired,
  };

  export default translate(Footer);